const header = {
  homepage: "#",
  title: "SP.",
};

const about = {
  name: "Sooryaprabhath",
  role: "React Developer",
  description:
    "Experienced React developer with a passion for creating efficient, scalable, and user-friendly web applications. Proven track record of translating complex design and business requirements into maintainable and high-performance code. Adept at collaborating with cross-functional teams to drive project success. Committed to staying current with emerging technologies to deliver cutting-edge solutions.",
  // resume: 'https://docs.google.com/document/d/1HziQl7ST5KGXcXN70myrvTK15Rzlw2QZXO4HUq8Z-l8/edit?usp=sharing',
  social: {
    linkedin: "https://www.linkedin.com/in/sooryaprabhath-poojary-547322171",
    github: "https://github.com/Sooryaprabhath",
  },
};

const projects = [
  {
    name: "Image-Converter",
    description:
      "Image formate changer where user can drag and drop image and change it's formate by selecting given option",
    stack: ["NEXT Js"],
    sourceCode: "https://github.com/Sooryaprabhath/Image_Converter",
    livePreview: "https://image-converter-nine.vercel.app/",
  },
  {
    name: "Floating-Cards",
    description:
      "Floating cards is a fun app where cards will float in the screen",
    stack: ["React", "Tailwind CSS", "Framer-Motion"],
    sourceCode: "https://github.com/Sooryaprabhath/Floating-Cards",
    livePreview: "https://floating-cards.vercel.app/",
  },
  {
    name: "Image-Slider",
    description:
      "A react slider where images slides while clicking on arrows provided in the UI",
    stack: ["React", "React Icons"],
    sourceCode: "https://github.com/Sooryaprabhath/Image-Slider",
    livePreview: "https://image-slider-two-rouge.vercel.app/",
  },
  {
    name: "User-Slider",
    description:
      "Uniqe slider for users - new concept of sliding experience with secured blur effect",
    stack: ["React", "Ant design"],
    sourceCode: "https://github.com/Sooryaprabhath/user-slider",
    livePreview: "#",
  },
  {
    name: "Easy Paste",
    description:
      "Easy Paste is a sticky note ectension created for chrome and it used as keeping needed credentials for imediate copying",
    stack: ["React"],
    sourceCode: "https://github.com/Sooryaprabhath/Sticky_Note_Chrome_Extension",
    livePreview: "https://chromewebstore.google.com/detail/easy-paste/bdaigfbcnhjignifmmafdlkcdlhlkmjh",
  },
  {
    name: "Tab Hopper",
    description:
      "Quickly jump to any tab of chrome and temporarily hide tabs with ease using snooze option.",
    stack: ["React"],
    sourceCode: "https://github.com/Sooryaprabhath/Tab-Mangement-extension",
    livePreview: "https://chromewebstore.google.com/detail/tab-management-extension/malmphnknfengkagchinflakccnkkgea",
  },
  {
    name: "Audi To Text",
    description:
      "Converts audio using microphone to a Text formate and displayes it.",
    stack: ["React"],
    sourceCode: "https://github.com/Sooryaprabhath/Autio-To-Text",
    livePreview: "https://autio-to-text.vercel.app/",
  },
];

const skills = [
  "HTML",
  "CSS",
  "JavaScript",
  "Google Analytics",
  "Google Tag Manager",
  "Looker",
  "React js / Reacr native",
  "Material UI",
  "Git, Github / Gitlab",
  "Firebase",
  "Node js",
  "Tailwind CSS",
  "Framer-Motion",
  "Vercel",
  "Netlify",
  "Next Js",
  "Astro Js"
];

const contact = {
  email: "sooryaprabhath123@mail.com",
};

export { header, about, projects, skills, contact };
